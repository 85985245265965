/* src/index.css */
body {
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-color: #f4f4f9;
}

.container {
  text-align: center;
}

input,
button {
  padding: 10px;
  margin: 10px;
  font-size: 16px;
}

#results {
  display: none;
}

#results.show {
  display: block;
}
